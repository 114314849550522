@use './config/' as *;
@forward './main.scss';
@forward './executive-board.scss';
.waf-faq,
.waf-about {
    @extend %page-head-blue-bg;
    @extend %accordion-common;
    &.waf-component {
        padding-block: 0 var(--space-20);
        @extend %mb-0;
    }
    .waf-head {
        .head-wrap {
            margin-block: 13rem 16rem;
        }
    }
    .waf-body {
        margin-top: var(--space-15-neg);
        @extend %bg-transparent;
        .link {
            text-decoration: underline;
            @extend %focus-0;
            @extend %body-mb;
        }
    }
    .tab {
        &s {
            @extend %p-6-4;
            @extend %border-radius-r;
            @extend %bg-neutral-0;
        }
        &-name {
            cursor: pointer;
            border-left: .1rem solid var(--neutral-0);
            @extend %p-4;
            &.active {
                border-left: .1rem solid var(--focus-0);
                .btn-text {
                    @extend %neutral-100;
                }
            }
        }
        &-text {
            .btn-text {
                @extend %neutral-70;
                @extend %body-m;
            }
        }
        &-container {
            @extend %gap-6;
            @extend %flex-column;
        }
        &-section {
            @extend %gap-14;
            @extend %flex-column;
        }
        &-container-wrap {
            @extend %border-radius-r;
            @extend %bg-neutral-0;
        }
    }
    .card {
        @extend %p-6-4;
        &-title {
            @extend %title-40;
            @extend %mb-6;
            @extend %neutral-100;
        }
        &-desc {
            @extend %mb-6;
            @extend %neutral-70;
            @extend %body-m;
        }
    }
    .accordion-body {
        .text {
            font-size: 1.4rem;
        }
        ul {
            list-style-type: disc;
            @extend %pl-5;
        }
        .table {
            border: .1rem solid hsl(var(--hsl-neutral-20)/1);
            &-title {
                @extend %body-mb;
            }
            &-head {
                border-bottom: .1rem solid hsl(var(--hsl-neutral-20)/1);
            }
            &-footer {
                border-top: .1rem solid hsl(var(--hsl-neutral-20)/1);
            }
            &-data {
                flex: 1;
            }
        }
    }
    .document {
        &-redirect {
            @extend %event-card-small;
            .card-item {
                width: max-content;
                min-height: 6.4rem;
                &::before {
                    opacity: 1;
                }
            }
        }
        &-list {
            @extend %mb-6;
        }
        &-link {
            text-decoration: underline;
        }
    }
    .history {
        &-list {
            flex-wrap: wrap;
            @extend %flex-n-c;
            @extend %gap-6;
            @extend %m-y-10-5;
            .thumbnail {
                height: 11rem;
            }
            .logo {
                @extend %h-100;
            }
        }
    }
    .waf-members {
        @extend %bg-transparent;
        .waf {
            &-head {
                @extend %d-none;
            }
            &-body {
                @extend %mt-0;
                @extend %p-0-2;
            }
        }
    }
    @extend %document-link;
}
@include mq(col-tablet) {
    .waf-faq,
    .waf-about {
        .waf-head {
            .head-wrap {
                margin-block: 23.3rem 15rem;
            }
        }
        .tab {
            &s {
                width: 30%;
                height: max-content;
                position: sticky;
                top: 4rem;
            }
            &-section {
                flex-direction: row;
                gap: var(--space-6);
            }
            &-container {
                width: 70%;
            }
        }
        .card {
            padding: var(--space-10);
            &-title {
                font-size: 3.5rem;
            }
        }
        .waf-members {
            .card-list {
                gap: var(--space-6);
                &-title {
                    font-size: 2.4rem;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-faq {
        .tab {
            &s {
                padding-inline: var(--space-5);
            }
            &-name {
                padding-inline: var(--space-5);
            }
            &-text {
                .btn-text {
                    font-size: 1.6rem;
                }
            }
        }
        .card {
            padding: var(--space-20) var(--space-24);
            &-title {
                font-size: 4rem;
            }
        }
    }
}