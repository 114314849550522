@use '../config/' as *;
.waf-members {
    @extend %page-head-blue-bg;
    @extend %vertical-tabs;
    @extend %member-item;
    @extend %mx-2-neg;
    &.waf-component {
        @extend %mb-0;
        @extend %p-0;
    }
    .waf-body {
        margin-top: var(--space-20-neg);
        max-width: unset;
        border-radius: 0;
        @extend %mb-8;
        // @extend %p-8-4;
        @extend %bg-transparent;
    }
    .head-wrap {
        margin-block: 11.12rem;
    }
    .tab-section {
        @extend %flex-column;
        @extend %gap-8;
    }
    .card {
        @extend %p-12-4;
        @extend %border-radius-m;
        @extend %bg-neutral-0;
        &:not(:last-child) {
            @extend %mb-6;
        }
        &-title {
            @extend %title-40;
        }
        &-list-title {
            @extend %mt-10;
            @extend %mb-8;
        }
        &-content-list {
            @extend %mt-6;
        }
        &-content-item {
            + .card-content-item {
                @extend %mt-6;
            }
        }
        &-list-wrap {
            + .card-list-wrap {
                @extend %mt-10;
            }
        }
        &-list {
            max-width: 98.3rem;
            @extend %mx-auto;
            @include card-count(2, var(--space-2), wrap);
        }
        &-thumbnail {
            aspect-ratio: 7/5;
        }
        &-name {
            @extend %mb-0;
            @extend %title-60;
        }
        &-item {
            @extend %relative;
        }
        &-action a {
            line-height: 1.2;
            // @extend %primary-50;
            @extend %body-s;
            @extend %d-block;
        }
    }
    .meta {
        &-position {
            @extend %neutral-70;
            @extend %d-block;
            @extend %body-s;
        }
        &-origin {
            @extend %my-2;
            @extend %gap-1;
            img {
                width: 0.8rem;
            }
            .team-name {
                @extend %body-s;
            }
        }
    }
    .position {
        @extend %body-s;
    }
    .btn-site {
        @extend %position-t-l;
        @extend %w-100;
        @extend %h-100;
        .text {
            @extend %font-0;
        }
    }
    .table {
        &-row {
            justify-content: flex-start;
            &:not(:last-child) {
                border-bottom: 0;
                margin-bottom: var(--space-3);
            }
        }
        &-data {
            height: auto;
            padding-inline: var(--space-1);
            display: block;
            word-break: break-word;
            &.name {
                flex-basis: 15rem;
                text-align: left;
            }
            &.country {
                flex-basis: 9.6rem;
            }
            &.year {
                flex-basis: 8rem;
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-members {
        .waf-head {
            // display: flex;
            // align-items: center;
            min-height: 60.8rem;
        }
        .head-wrap {
            // margin-block: 10rem 0rem;
            margin-block: 33.4rem 0rem;
        }
        .tab {
            &-container-title {
                //temporary
                font-size: 4rem;
            }
            &-text {
                .btn-text {
                    font-size: 1.6rem;
                }
            }
            &s {
                border-radius: 2.4rem;
            }
        }
        .card {
            border-radius: 2.4rem;
            padding: var(--space-12) var(--space-6);
            &-list-title {
                font-size: 2.4rem;
                margin-bottom: var(--space-8);
            }
            &-list-wrap {
                + .card-list-wrap {
                    margin-top: var(--space-20);
                }
            }
            &-thumbnail {
                aspect-ratio: 5/3;
            }
            &-action a {
                font-size: 1.4rem;
            }
        }
        .position {
            font-size: 1.6rem;
        }
        .table {
            &-data {
                &.name {
                    flex-basis: 70%;
                }
                &.country {
                    flex-basis: 17%;
                }
                &.year {
                    flex-basis: 13%;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-members {
        .head-wrap .title {
            margin-left: var(--space-10);
        }
        .waf-body {
            max-width: var(--container-max-width);
            margin-inline: auto;
        }
        .tab-section {
            flex-direction: row;
            gap: var(--space-6);
        }
        .tab {
            &s {
                width: 33.33%;
                height: max-content;
                position: sticky;
                top: calc(var(--header-height) + var(--header-redirect-height));
            }
            &-container {
                width: 66.67%;
                height: max-content;
            }
        }
        .card {
            &-list {
                @include card-count(3, var(--space-6), wrap);
            }
            &-title {
                font-size: 4rem;
            }
        }
    }
}